<template>
  <app-side-menu>
    <aside
      class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          EstImage Message Details
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input
                class="input is-capitalized"
                type="text"
                v-model="value.uid"
                placeholder="Job ID"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                class="input is-capitalized"
                type="text"
                v-model="value.messageNo"
                placeholder="Message No."
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select">
                <select v-model="value.messageTypeEnum">
                  <option disabled
                    value="">-- Message Type --</option>
                  <option :value="messageTypeEnum.EstImageRequestForQuote">Request For Quote</option>
                  <option :value="messageTypeEnum.EstImageExcessUpdate">Excess Updated</option>
                  <option :value="messageTypeEnum.EstImageRegoUpdate">Rego Updated</option>
                  <option :value="messageTypeEnum.EstImageAuthorise">Quote Authorised</option>
                  <option :value="messageTypeEnum.EstImageCancel">Job Cancelled</option>
                  <option :value="messageTypeEnum.EstimageQuote">Quote</option>
                  <option :value="messageTypeEnum.EstimageInvoice">Tax Invoice</option>
                  <option :value="messageTypeEnum.EstimageVehicle">Vehicle Details</option>
                  <option :value="messageTypeEnum.EstimageImage">Images</option>
                  <option :value="messageTypeEnum.EstimageRepairTimeline">Repair Timeline</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select">
                <select v-model="value.messageStatusTypeEnum">
                  <option disabled
                    value="">-- Message Status --</option>
                  <option :value="messageStatusTypeEnum.ToSend">To Send</option>
                  <option :value="messageStatusTypeEnum.Sent">Sent</option>
                  <option :value="messageStatusTypeEnum.UnRead">UnRead</option>
                  <option :value="messageStatusTypeEnum.Read">Read</option>
                  <option :value="messageStatusTypeEnum.Failed">Failed</option>
                  <option :value="messageStatusTypeEnum.InComplete">In Complete</option>
                  <option :value="messageStatusTypeEnum.Deleted">Deleted</option>
                  <option :value="messageStatusTypeEnum.DeletedRecord">Deleted Record</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select">
                <select v-model="value.messageDirection">
                  <option disabled
                    value="">-- Message Direction --</option>
                  <option value="0">Incoming</option>
                  <option value="1">Outgoing</option>
                </select>
              </div>
            </div>
          </div>

        </li>
      </ul>
      <hr>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { MessageStatusTypes, MessageTypes } from '@/enums'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'EstimageMessageNewSideMenu',
  components: {
    AppSideMenu
  },
  props: {},
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      value: {
      messageStatusTypeEnum: 30,
      messageDirection: 0,
      messageTypeEnum: 42
    }
    }
  },
  computed: {
    messageStatusTypeEnum() {
      return MessageStatusTypes
    },
    messageTypeEnum() {
      return MessageTypes
    }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  }
}
</script>
